import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="logo-omm" />
    <p>By <Link to="/">Omnimion</Link> 2025. See our <Link to="/policy">Privacy and Data Protection Policy</Link> and <Link to="/terms-of-service">Terms of Service</Link>.</p>
  </footer>
);

export default Footer;
