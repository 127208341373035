import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button } from "reactstrap";
import Loading from "../Loading";
import { getConfig } from "../../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from 'react';
import Plotly from 'plotly.js/dist/plotly-cartesian'
import { Progress }from "reactstrap";

export const RiverflowStationDetailsComponent = (props) => {   
  
  const [stationData, setStationData] = useState(null);
  const [similarStationData, setSimilarStationData] = useState(null);
  
  const identifier = props.match.params.id;

  const { audience } = getConfig();
  const apiOrigin = audience.replace(/\/$/, ""); 
  
  const { getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    async function getStationData(stationId){ 
      const token = await getAccessTokenSilently();
      const url = apiOrigin + `/riverflows/station/${stationId}`;
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const stationData = await response.json()
      setStationData(stationData);

      const plotData = [
        {
          x: stationData.timeseriesTimes,
          y: stationData.timeseriesValues,
          type: 'scatter'
        }
      ];
      Plotly.react('riverflows-timeseries', plotData)

      const urlSimilarity = apiOrigin + `/riverflows/station/${stationId}/suggest?metric=cosine&limit=10`;
      const responseSimilarity = await fetch(urlSimilarity, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const similarStations = await responseSimilarity.json();
      setSimilarStationData(similarStations["data"]);

    }
    getStationData(identifier);
  }, [identifier, apiOrigin, getAccessTokenSilently])

    return (
      <Fragment>
        <Link to="/riverflows"><Button className="secondary" outline>&lt;Back</Button></Link>
        <h2>Station details</h2>
        {!stationData && <Progress animated bar value="100" style={{height: '15px'}}>Loading station details...</Progress>}
        {stationData && <Fragment>
        <h3>{identifier}: {stationData.name}</h3>
        <p>{stationData.description}</p>
        <p>Opened: {stationData.opened}, Closed: {stationData.closed ? stationData.closed : "still open"}, Catchment area: {stationData.catchmentArea} km squared</p>
        </Fragment>
        }
        <div>
        <h3>Similar stations:</h3>
        {similarStationData && similarStationData.map((station) => {
            return <Link key={station.id} to={`/riverflows/nrfa/${station.id}`}><Button className="secondary" outline>{station.id}: {station.name}</Button></Link>
        })}
        {!similarStationData &&  
          <Progress animated bar value="100" style={{height: '15px'}}>Loading similar stations...</Progress>
        }
        </div>

        <div id="riverflows-timeseries">
        </div>

        <a href={`https://nrfaapps.ceh.ac.uk/nrfa/ws/station-info?station=${identifier}&fields=all&format=json-object`} rel="noreferrer" target="_blank">
        <Button className="secondary" outline>
        Station info json
        </Button>
        </a>
        <a href={`https://nrfaapps.ceh.ac.uk/nrfa/ws/time-series?station=${identifier}&data-type=gdf&format=json-object`} rel="noreferrer" target="_blank">
        <Button className="secondary" outline>
        Time series json
        </Button>
        </a>
      </Fragment>
    )
};

export default withAuthenticationRequired(RiverflowStationDetailsComponent, {
  onRedirecting: () => <Loading />,
});