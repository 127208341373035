import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Hydrosheds from "./views/Hydrosheds";
import LandCover from "./views/LandCover";
import RiverFlowList from "./views/RiverFlowList";
import RiverflowStationDetails from "./components/riverflows/RiverflowStationDetails"
import Home from "./views/Home";
import Profile from "./views/Profile";
import ImageStats from "./views/ImageStats";
import UserSettings from "./views/UserSettings";
import CrsTransformation from "./views/CrsTransformation";
import Random from "./views/Random";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfService from "./views/TermsOfService";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";


initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oh, there was a problem... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/user-settings" component={UserSettings} />
            <Route path="/crs-transformation" component={CrsTransformation} />
            <Route path="/random" component={Random} />
            <Route path="/imstats" component={ImageStats} />
            <Route path="/hydrosheds" component={Hydrosheds} />
            <Route path="/landcover" component={LandCover} />
            <Route path="/riverflows/nrfa/:id"  component={RiverflowStationDetails} loader={() => {console.log("loader");}} />
            <Route path="/riverflows" component={RiverFlowList} />
            <Route path="/policy/" exact component={PrivacyPolicy} />
            <Route path="/terms-of-service/" exact component={TermsOfService} loader={() => {console.log("loader");}} />
            
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
