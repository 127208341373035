import React from "react";
import { Container } from "reactstrap";

export const PrivacyPolicyComponent = () => {

  return (
    <Container className="mb-5">
            <h1>Privacy and Data Protection Policy</h1>
            <h2>Introduction</h2>
            <p>Omnimion is open to all kinds of experiments with data - storage, analysis, processing, and visualization. We specialize in spatial and multidimenstional data. Our services are experimental in nature and we do not guarantee any specific availability and performance. The website is a place where we invite our customers to explore possible solutions with us and see what we can do. We take data protection and your privacy very seriously. This policy describes the measures we take to keep your data and personal information secure.</p>
            <h2>What Data We Collect</h2>
            <p>When you sign up and log in to omnimion.com, we collect your:</p>
            <ul>
                <li>Personal information, specifically <strong>full name</strong>and <strong>email</strong>.</li>
                <li>If you sign up using omnimion.com's username and password (i.e. not using Google, GitHub, etc.), we store the hashed password and anage authentication using <a href="https://auth0.com" target="_blank" rel="noreferrer">Auth0</a>.</li>
                <li>If you sign up with external identity provider (i.e. Google, GitHub, etc.), we will have access to the information provided in the tokens generated by these platforms, but we do not require access to any of their scopes and permissions other than <strong>email</strong>.</li>
                <li>We also store information about your settings for omnimion.com to personalize the content you see. For example, we allow you to define default parameters of some tools so that you don't have to specify these parameters every time.</li>
            </ul>
            <h2>Cookies</h2>
            <p>We use only cookies required for the authentication mechanism to work securely and these cookies are necessary if you want to use ominimion.com. It is <code>_legacy_auth0.K3JmLlT1JBt5ZudKCX2udqljcvkiM9dh.is.authenticated</code> and <code>auth0.K3JmLlT1JBt5ZudKCX2udqljcvkiM9dh.is.authenticated</code></p>

            <h2>How We Use the Data</h2>
            <p>We use your data to provide the functionality of omnimion.com and to monitor performace and usage of our services.</p>
            
            <h2>How We Share Data</h2>
            <p>We do not provide any information to third parties, except for information necessary for OAuth2 authentication, that is the emaul and password or email and identity provider name.</p>

            <h2>Data Storage and Security</h2>
            <p>To protect your personal data we use the following measures:</p>
            <ul>
                <li>The omnimion.com website uses encryption for all trafic between servers</li>
                <li>We don't store inforation that is not necessary for the functionality of omniion.com.</li>
            </ul>

            <h2>User Rights</h2>
            <p>As a user of omnimion.com you have the right to:</p>
            <ul>
                <li>stop using our services at any point in time</li>
                <li>ask us what information about you we have</li>
                <li>tell us that you want all your pesonal data and other data to be deleted. You tell us by emailing to <code>policy@omnimion.com</code> with the subject <pre>[delete data]</pre>.</li>
            </ul>
            
            <h2>Changes to the Privacy Policy</h2>
            <p>We may update the policy, in which case we will use your email to inform you about the changes.</p>
            
            <h2>Contact Information</h2>
            <p>For further questions about privacy of your data, you can contact Omnimion at <code>policy@omnimion.com</code>.</p>
      
    </Container>
  );
};

export default PrivacyPolicyComponent;
