import React from "react";
import { Link } from "react-router-dom"
import { Container } from "reactstrap";

export const TemrsOfServiceComponent = () => {

  return (
    <Container className="mb-5">
            <h1>Terms of Service</h1>
            
            <p>Effective Date: 1st January 2025</p>

            <h2>Introduction</h2>
            <p>Omnimion is open to all kinds of experiments with data - storage, analysis, processing, and visualization. We specialize in spatial and multidimenstional data. Our services are experimental in nature and we do not guarantee any specific availability and performance. The website is a place where we invite our customers to explore possible solutions with us and see what we can do.</p>
            <p>These Terms of Service ("Terms") govern your use of our services and APIs (the "Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms.</p>

            <h2>Acceptance of Terms</h2>
            <p>By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree, do not access or use the Service.</p>

            <h2>Use of the Service</h2>
            <p>You may use the Service only in accordance with applicable laws and regulations.</p>
            <p>You are responsible for maintaining the confidentiality of your API keys and other credentials for all activities performed using it.</p>
            <p>Unauthorized scraping, reverse engineering, or excessive use that disrupts our systems is prohibited.</p>
            
            <h2>Intellectual Property</h2>
            <p>Unless explicitely stated otherwise, the Service, including its code, design, and content, is owned by omnimion.com and protected under applicable copyright and intellectual property laws.</p>
            <p>Explicit exceptions are:</p>
            <ul>
              <li><strong>Basin delineation service</strong> <code>/hydrosheds/basin</code> uses data produced and described by Lehner, B., Verdin, K., Jarvis, A. (2008): New global hydrography derived from spaceborne elevation data. Eos, Transactions, 89(10): 93-94. Data available at <a href="https://www.hydrosheds.org" target="_blank" rel="noreferrer">https://www.hydrosheds.org</a>. The service providing the basins fro the data can still be used for scientific, educational and commercial use.</li>
            </ul>

            <h2>Privacy</h2>
            <p>Your use of the Service is also governed by our Privacy and Data Protection Policy, which can be found <Link to="/policy">here</Link>.</p>

            <h2>Disclaimer of Warranties</h2>
            <p>The Service is provided "as is" and "as available," without warranties of any kind, express or implied. We do not guarantee uninterrupted or error-free operation of the Service.</p>

            <h2>Limitation of Liability</h2>
            <p>To the extent permitted by law, omnimion.com is not liable for any direct, indirect, incidental, or consequential damages resulting from your use or inability to use the Service.</p>

            <h2>Termination</h2>
            <p>We reserve the right to terminate or suspend access to the Service at our sole discretion, without prior notice, for any reason, including a breach of these Terms.</p>

            <h2>Modifications</h2>
            <p>We may update these Terms from time to time. The updated Terms will be posted on this page with a revised "Effective Date." Continued use of the Service constitutes acceptance of the updated Terms.</p>

            <h2>Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of the European Union. Any disputes arising under these Terms will be resolved in accordance with the applicable EU laws and, where necessary, in the courts of the Czech Republic.</p>

            <h2>Contact Information</h2>
            <p>For further questions about Privacy of your data, you can contact Omnimion at <code>policy@omnimion.com</code>.</p>
    </Container>
  );
};

export default TemrsOfServiceComponent;
