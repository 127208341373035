import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';

import React, { Fragment, useEffect, useState, useRef } from "react";
import Loading from "../components/Loading";
import { Form, FormGroup, Label, Input, Progress, Button } from 'reactstrap';
import 'ol/ol.css';
import { Map } from 'ol';
import { View } from 'ol';
import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource, TileWMS } from 'ol/source';
import { MultiPolygon,  Polygon } from "ol/geom";
import GeoJSON from 'ol/format/GeoJSON';
import { getConfig } from "../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import TileLayer from "ol/layer/Tile";
import { transform } from "ol/proj";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import ImageTile from "ol/source/ImageTile";
import LayerSwitcher from 'ol-layerswitcher';
import LandCoverLegendComponent from '../components/LandCoverLegend';

export const LandCoverComponent = () => {
  
    const [snappingFactor, setSnappingFactor] = useState(0.0);
    const [message, setMessage] = useState("Click on a map to get a catchment");
    const [animated , setAnimated] = useState(false);
    const vectorSourceRef = useRef(new VectorSource());

    const {
        getAccessTokenSilently        
      } = useAuth0();

    const { audience } = getConfig();
    const apiOrigin = audience.replace(/\/$/, "");
    var wgs84 = "EPSG:4326";
    var mercator = "EPSG:3857";
    var style = new Style({
       fill: new Fill({color: 'rgba(180, 110, 0, 0.3)'}),
       stroke: new Stroke({color: 'rgba(180, 110, 0, 1)', width: 4})
    });

    const SNAPPING_FACTOR_OPTIONS = {
      "None": 0.0,
      "Small": 0.001,
      "Medium": 0.01,
      "Large": 0.1
    }

    const handleSnappingFactorChange = async (event) => {
      const snapping = SNAPPING_FACTOR_OPTIONS[event.target.value];
      setSnappingFactor(snapping);
    };

    const triggerDownload = (geoJsonString, fileName = 'features.geojson') => {
      const blob = new Blob([geoJsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    };

    const handleDownload = async (event) => {
      const features = vectorSourceRef.current.getFeatures();
      if(features.length < 1){
        alert("Nothing to download yet, click on the map first.");
      }
      const geoJsonFormat = new GeoJSON();
      const geoJsonString = geoJsonFormat.writeFeatures(features, {
        featureProjection: mercator,
        dataProjection: wgs84,
      });

      triggerDownload(geoJsonString);
    };

    const handleMapClickAsync = async (event) => {
      try {
        setMessage("Loading...");
        setAnimated(true);

        const map = event.target;
        var lonlat = transform(event.coordinate, mercator, wgs84);
        
        // TODO: It would be nice to use the snappingFactor state in the url below, but this works too.
        const snappFactorName = document.getElementById("snappingSelect").value
        const snappFactor = SNAPPING_FACTOR_OPTIONS[snappFactorName];
        
        const url = `${apiOrigin}/hydrosheds/basin?lon=${lonlat[0]}&lat=${lonlat[1]}&snapping=${snappFactor}`;
        
        const token = await getAccessTokenSilently();
        
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok){
  
          const responseData = await response.json();
          const geometryJson = responseData.geometry;
          var mapProjection = map.getView().getProjection();
          
          let geometry = null;
          if (geometryJson.type === "Polygon") {
            const mercatorCoordinates = geometryJson.coordinates.map( (ring) =>
              ring.map((coordinate) => transform(coordinate, wgs84, mapProjection))
            );
            geometry = new Polygon(mercatorCoordinates);
            console.log(JSON.stringify(mercatorCoordinates)); // DBUG
          } else {
            const transformedCoordinates = geometryJson.coordinates.map((polygon) =>
              polygon.map((ring) =>
                ring.map((coordinate) => transform(coordinate, wgs84, mapProjection))
              )
            );
            console.log(JSON.stringify(transformedCoordinates)); // DBUG
            geometry = new MultiPolygon(transformedCoordinates);
          }

          const catchmentFeature = new Feature({"properties": responseData.properties, "geometry": geometry});
          catchmentFeature.setStyle(style);
          
          vectorSourceRef.current.addFeature( catchmentFeature );

          setMessage("success");
          setAnimated(false)
        } else {
          const responseData = await response.json();
          setMessage(`Error: ${response.status} ${responseData.detail}`);
          setAnimated(false);
        }

  
      } catch (error) {
        console.log(error);
        setMessage('error');
      }
    };

    useEffect(() => {

      var center = transform([13.0, 51.0], wgs84, mercator)

      const euHydroLayer = new TileLayer({
        title: "EU Hydro",
        source: new TileWMS({          
          url: 'https://image.discomap.eea.europa.eu/arcgis/services/EUHydro/EUHydro_RiverNetworkDatabase/MapServer/WMSServer',
          params: {'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20'},
          transition: 0,
        }),
      });

      const landcoverLayer = new TileLayer({
        title: "Landcover",
        //extent: [-13884991, 2870341, -7455066, 6338219],
        source: new TileWMS({
          attributions: "This publication has been prepared using European Union's Copernicus Land Monitoring Service information; https://doi.org/10.2909/71fc9d1b-479f-4da1-aa66-662a2fff2cf7>",
          // Satellite Imagery has restricted access :(
          //url: 'https://image.discomap.eea.europa.eu/arcgis/services/GioLand/VHR_2021_LAEA/ImageServer/WMSServe',
          //params: {'LAYERS': 'VHR_2021_LAEA', 'TILED': false},

          url: 'https://copernicus.discomap.eea.europa.eu/arcgis/services/CLC_plus/CLMS_CLCplus_RASTER_2021_010m_eu/ImageServer/WMSServer',
          params: {'LAYERS': 'CLMS_CLCplus_RASTER_2021_010m_eu'},
          
          //url: 'https://image.discomap.eea.europa.eu/arcgis/services/GioLandPublic/HRL_ImperviousnessDensity_2018/ImageServer/WMSServer',
          //params: {'LAYERS': 'HRL_ImperviousnessDensity_2018'},

          //serverType: 'geoserver',
          // Countries have transparency, so do not fade tiles:
          transition: 0,
        }),
      });

      const esriWorldTopoLayer = new TileLayer({
        source: new ImageTile({
          attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        })
      });

      
      const worldLandCoverLayer = new TileLayer({
        source: new ImageTile({
          attributions: 'Global Land Cover',
          url: 'https://s3-eu-west-1.amazonaws.com/vito-lcv/global/2019/cog-full_l0-colored-full/{z}/{x}/{y}.png'
        })
      });
      

      const map = new Map({
        target: "map-element",
        layers: [
          new TileLayer({ 
            title: "OpenStreetMap",
            source: new OSM(),
          }),
          // esriWorldTopoLayer,
          // worldLandCoverLayer,
          landcoverLayer,
          euHydroLayer,
          new VectorLayer({
            title: "Basins",
            source: vectorSourceRef.current
          }),
        ],
        view: new View({
          center: center,
          zoom: 5,
          projection: mercator
        })
      });

      const layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'children'
      });

      map.addControl(layerSwitcher);

      map.on("click", handleMapClickAsync)

      return () => { map.setTarget(null) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []
  );

    return (
        <Fragment>
        <div className="map-page">
          <Form>
          <FormGroup>
            <Label for="snappingSelect">
              Snapping factor (circa {snappingFactor} km)&nbsp;&nbsp;
            </Label>
            <Input
              id="snappingSelect"
              name="select"
              type="select"
              onChange={ handleSnappingFactorChange }
            >
              <option>None</option>
              <option>Small</option>
              <option>Medium</option>
              <option>Large</option>
            </Input>
            <span>&nbsp;</span>
            <Button key="download" id="download" color="primary" size="sm" onClick={handleDownload}>Download GeoJson</Button>
            <LandCoverLegendComponent />
          </FormGroup>
          </Form>
          <div id="panel" className="panel">
            <div>{message}</div>
            <Progress
              animated={ animated }
              bar
              value="100"
              style={{height: '15px'}}
            >
            </Progress>
          </div>
          <div id="map-element" className="map-ol">
          </div>
        </div>
      </Fragment>
    )
};


export default withAuthenticationRequired(LandCoverComponent, {
    onRedirecting: () => <Loading />,
  });