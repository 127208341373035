import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function LandCoverLegendComponent(args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="primary" size="sm" onClick={toggle}>
        Show land cover legend
      </Button>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Land cover legend</ModalHeader>
        <ModalBody>
            <p><span style={{"background-color": "rgb(230, 230, 230)"}}>&nbsp;&nbsp;&nbsp;</span> Outside area</p>
            <p><span style={{"background-color": "rgb(255, 0, 0)"}}>&nbsp;&nbsp;&nbsp;</span> Sealed</p>
            <p><span style={{"background-color": "rgb(34, 139, 34)"}}>&nbsp;&nbsp;&nbsp;</span> Woody needle leaved trees</p>
            <p><span style={{"background-color": "rgb(0, 255, 8)"}}>&nbsp;&nbsp;&nbsp;</span> Woody Broadleaved deciduous trees</p>
            <p><span style={{"background-color": "rgb(0, 255, 8)"}}>&nbsp;&nbsp;&nbsp;</span> Woody Broadleaved evergreen trees</p>
            <p><span style={{"background-color": "rgb(128, 64, 0)"}}>&nbsp;&nbsp;&nbsp;</span> Low-growing woody plants</p>
            <p><span style={{"background-color": "rgb(204, 242, 77)"}}>&nbsp;&nbsp;&nbsp;</span> Permanent herbaceous</p>
            <p><span style={{"background-color": "rgb(255, 255, 128)"}}>&nbsp;&nbsp;&nbsp;</span> Periodically herbaceous</p>
            <p><span style={{"background-color": "rgb(255, 128, 255)"}}>&nbsp;&nbsp;&nbsp;</span> Lichens and mosses</p>
            <p><span style={{"background-color": "rgb(191, 191, 191)"}}>&nbsp;&nbsp;&nbsp;</span> Non and sparsely vegetated</p>
            <p><span style={{"background-color": "rgb( 0, 128, 255)"}}>&nbsp;&nbsp;&nbsp;</span> Water</p>
            <p><span style={{"background-color": "rgb( 0, 255, 255)"}}>&nbsp;&nbsp;&nbsp;</span> Snow and ice</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default LandCoverLegendComponent;