import React, { Fragment, useState } from "react";
import Loading from "../components/Loading";
import Highlight from "../components/Highlight";
import { getConfig } from "../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ImageStatsComponent = () => {

    const [file, setFile] = useState(null);
    const [imageStats, setImageStats] = useState(null);

    const {
        getAccessTokenSilently
      } = useAuth0();

    const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };

      const handleUpload = async () => {
        const { audience } = getConfig();
        const apiOrigin = audience.replace(/\/$/, "");
        const url = `${apiOrigin}/image/stats/`;
        if (file) {
          console.log('Uploading file...');
      
          const formData = new FormData();
          formData.append('file', file);
      
          try {
            const token = await getAccessTokenSilently();

            const result = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
      
            const data = await result.json();
      
            console.log(data);

            // 
            document.getElementById('image-stat-results').innerText = JSON.stringify(data);
            // I don't know why setImageStats does not rerender the results after the first file has been uploaded.
            setImageStats("");
            setImageStats(data);
            console.log("setImageStats called")
          } catch (error) {
            console.error(error);
          }
        }
      };

    return (
        <Fragment>
        <div className="input-group">
          <input id="file" type="file" onChange={handleFileChange} />
        </div>
        {file && (
          <section>
            File details:
            <ul>
              <li>Name: {file.name}</li>
              <li>Type: {file.type}</li>
              <li>Size: {file.size} bytes</li>
            </ul>
          </section>
        )}
  
        {file && (
          <button 
            onClick={handleUpload}
            className="submit"
          >Upload a file</button>
        )}
      
      <div id="image-stat-results"></div>
      <div className="result-block-container">
        {imageStats && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <p>{JSON.stringify(imageStats, null, 2)}</p>
            <Highlight>
            <span>{JSON.stringify(imageStats, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div>
      </Fragment>
    )
};


export default withAuthenticationRequired(ImageStatsComponent, {
    onRedirecting: () => <Loading />,
  });