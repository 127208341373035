import React, { Fragment, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { getConfig } from "../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";

export const RiverflowListComponent = () => {

    const [stationsData, setStationsData] = useState([]);
    
    const {
        getAccessTokenSilently        
      } = useAuth0();

    const { audience } = getConfig();
    const apiOrigin = audience.replace(/\/$/, "");    

    useEffect(() => {
      async function getStationData(){
        const token = await getAccessTokenSilently();
        const url = apiOrigin + `/riverflows/station/list/`;
        const response = await fetch(url,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const data = await response.json();
        
        setStationsData(data["data"]);
    }
    getStationData();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    return (
        <Fragment>
        <div>
          <h1>Find similar river gauging stations</h1>
          <p>Discover similar river gauging stations from the UK's National River Flow Archive (NRFA). Similarity is determined by comparing distribution of flow values. For each station, flow values were normalized to a an interval between 0.0 and 1.0 and binned into 100 regular subintervals. This constructed a 100-dimensional vector for each station. A <a href="https://en.wikipedia.org/wiki/Similarity_measure" rel="noreferrer" target="_blank">similarity distance metric</a> is used to find stations similar to your selected station. This website uses cosine distance metric. The backend supports also <code>L1</code> for Manhattan, <code>L2</code> for Euclidean, and <code>innerproduct</code> distance metrics.</p>
          <div id="riverflow-content">
            <h4>Available stations:</h4>
            { stationsData.length > 0 &&
                stationsData.map((d) => { 
                  return <div key={d.id}>
                    <p key={d.id}>
                        <Link to={`/riverflows/nrfa/${d.id}`}><Button outline><strong>{d.id}</strong>: {d.name}</Button></Link>
                    </p>
                  </div>
                })
            }
          </div>
        </div>
      </Fragment>
    )
};

export default withAuthenticationRequired(RiverflowListComponent, {
  onRedirecting: () => <Loading />,
});